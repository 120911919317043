jQuery(document).on('ready', function() {

    // Handle multiple search bars
    var searchBar1 = document.getElementById('search-field'); // The search bar on your main page
    // var searchBar2 = document.getElementById('s'); // The search bar on your search.php page
    // var searchBar3 = document.querySelector('.custom-media-search li.sf-field-search input'); // The search bar on your search.php page

    // Function to initialize predictive search
    function initializePredictiveSearch(searchBar) {
        if (!searchBar) return; // If the search bar does not exist, do nothing

        var resultsContainer = document.createElement('div');
        resultsContainer.setAttribute('class', 'search-results');
        resultsContainer.setAttribute('id', 'search-results');
        resultsContainer.style.position = 'absolute';
        resultsContainer.style.backgroundColor = 'transparent';
        resultsContainer.style.width = "100%";
        searchBar.parentNode.insertBefore(resultsContainer, searchBar.nextSibling);

        // Create spinner element
        var spinner = document.createElement('div');
        spinner.setAttribute('id', 'loading-spinner');
        spinner.style.display = 'none'; // Hidden by default
        spinner.innerHTML = '<div class="spinner"></div>'; // Simple spinner div
        searchBar.parentNode.insertBefore(spinner, resultsContainer);

        var debounce;
        searchBar.addEventListener('input', function() {

            var searchQuery = this.value;

            clearTimeout(debounce);
            debounce = setTimeout( 
              function () { 

                if (searchQuery.length > 0) {
                    spinner.style.display = 'block'; // Show spinner
                    resultsContainer.style.display = 'none'; // Hide the results container

                    /*
                    fetch(live_search_object.ajax_url + '?query=' + encodeURIComponent(searchQuery))
                        .then(response => response.json())
                        .then(data => {
                            spinner.style.display = 'none'; // Hide spinner
                            displaySearchResults(data, resultsContainer);
                        }).catch(error => {
                            spinner.style.display = 'none'; // Hide spinner on error
                            console.error('Error:', error);
                        });
                    */

                    var data = {
                        action: 'live_search',
                        query: encodeURIComponent(searchQuery),
                    };

                    console.log('prior to ajax');
                    console.log(data);

                    jQuery.ajax({
                        type: 'post',
                        url: live_search_object.ajax_url,
                        data: data, 
                        success: function(response) {

                            spinner.style.display = 'none'; // Hide spinner

                            displaySearchResults( response.data, resultsContainer);

                        },
                        error: function(response) {

                            // do nothing

                        },
                    });


                } else {
                    resultsContainer.innerHTML = '';
                    if (searchQuery.length == 0) {
                        resultsContainer.style.display = 'none'; // Hide the results container
                    }
                }
 
              }, 500, searchQuery
            );

        });
    }

    // Initialize for both search bars
    initializePredictiveSearch(searchBar1);
    // initializePredictiveSearch(searchBar2);
    // initializePredictiveSearch(searchBar3);



    function displaySearchResults(results, container) {
        // Clear previous results
        container.innerHTML = '';
        console.log(results);
        // Check if there are results
        if (results.length === 0) {
            console.log('results 0');
            container.classList.remove('open');
            container.classList.add('close');
            container.innerHTML = '<li>No results found</li>';
        } else {
            console.log('results 1');
            container.classList.remove('close');
            container.classList.add('open');
            container.style.display = 'block';

            // Create the search results container
            var searchResultsContainer = document.createElement('div');
            searchResultsContainer.id = 'search-results-container';

            // Create a list to display the results
            var resultsList = document.createElement('ul');
            resultsList.classList.add( 'search-results-list' );
            resultsList.id = 'search-results-list';
            resultsList.style.listStyleType = 'none'; // Remove bullet points

            // Append the results list to the search results container
            searchResultsContainer.appendChild(resultsList);

            container.appendChild(searchResultsContainer);

            // Append each result as a list item
            results.forEach(function(result) {

                var listItem = document.createElement('li'); // Create <li> element
                listItem.className = 'search-result-item'; // Add a class for styling

                // check if this is the "no results" output
                if( result.ID == 0 || result.ID == '0' ) {

                    if( result.post_link !== '' ) {

                        listItem.innerHTML = '<a class="view-all" href="' + result.post_link + '" target="_self">' + result.post_title + '</a>'; // Create a link

                    }else{

                        listItem.innerHTML = '<div class="no-result">' + result.post_title + '</div>';

                    }

                }else{

                    var target = '_self';
                    var post_title = result.post_title;
                    var download_svg = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 15L3.75 8.75L5.5 6.9375L8.75 10.1875V0H11.25V10.1875L14.5 6.9375L16.25 8.75L10 15ZM2.5 20C1.8125 20 1.22396 19.7552 0.734375 19.2656C0.244792 18.776 0 18.1875 0 17.5V13.75H2.5V17.5H17.5V13.75H20V17.5C20 18.1875 19.7552 18.776 19.2656 19.2656C18.776 19.7552 18.1875 20 17.5 20H2.5Z" fill="white"/></svg>';

                    if( result.post_type && ( result.post_type == 'regulatory_docs' || result.post_type == 'vitol-documents' || result.post_type == 'vitol-brasil-document' ) ) {
                        target = '_blank';
                        post_title = post_title + ' (PDF) ' + download_svg;
                    }

                    listItem.innerHTML = '<a class="search-result-link" href="' + result.post_link + '" target="' + target + '">' + post_title + '</a>'; // Create a link for each result

                }

                resultsList.appendChild(listItem);
            });
        }
    }


});